<div *ngIf="chartConfig"class="chart-container" >


    <canvas baseChart  #chartCanvas
    [data]="chartConfig.data"
    [options]="chartConfig.options"
    [type]="chartConfig.type"
    (chartClick)="chartClicked($event)">

</canvas>
  </div>