<div style="max-width: 600px; margin: 0 auto; padding: 20px; background-color: #fff; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
    <h1>Wählen Sie eine Hauptkategorie und Unterkategorien</h1>
  
    <mat-card>
      <mat-form-field appearance="fill" style="width: 100%; margin-bottom: 16px;">
        <mat-label>Hauptkategorie</mat-label>
        <mat-select [(value)]="selectedMainCategory" multiple>
          <mat-option *ngFor="let category of oberkategorie " [value]="category.value">
            {{ category.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card>
<div >

    <span *ngFor="let category of selectedMainCategory "> 
        <h1>{{ category }} </h1>
    <mat-list>
        <mat-list-item *ngFor="let subCategory of getFilteredKrankheiten(category)">
            <mat-checkbox 
                (change)="onSubCategoryChange(subCategory, $event.checked)">
                {{ subCategory.Unterkategorie }}
            </mat-checkbox>
        </mat-list-item>
    </mat-list>

</span>
</div>
  
    <mat-card style="margin-top: 20px;">
      <button mat-raised-button color="primary" (click)="confirmSelection()" [disabled]="getCheckedItems().length == 0">
        Auswahl bestätigen
      </button>
    </mat-card>
  </div>
  