import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { HospitalService } from '../service/hospitalService/hospital.service';
import { MatDialog } from '@angular/material/dialog';
import { KrankenhausDialogComponent } from './dialog/krankenhaus-dialog/krankenhaus-dialog.component';
import { KrankheitenDialogComponent } from './dialog/krankheiten-dialog/krankheiten-dialog.component';
import { PopDialogComponent } from './dialog/pop-dialog/pop-dialog.component';
import { RegioService } from '../service/regioservice/regioservice.component';
import { RoutingserviceComponent } from '../service/routingservice/routingservice.component';
import { UmkreisDialogComponent } from './dialog/umkreis-dialog/umkreis-dialog.component';



interface Hospital {
  Object_key: string;
  name: string;
  only_day_clinic: boolean;
  anzahl_betten_total: number;
  ambulant_fallzahlen_total: number;
  stationaer_fallzahlen_total: number;
  anzahl_fachabteilungen: number;
  geometry: {
    type: string;
    coordinates: number[];
  };
}

interface Disease {
  Unterkategorie: string;
  Hauptkategorie: string;

}

@Component({
  selector: 'app-krankenhausreform',
  templateUrl: './krankenhausreform.component.html',
  styleUrls: ['./krankenhausreform.component.css']
})
export class KrankenhausreformComponent implements OnInit{
  currentYear: number = 2025;
  customIconRed = L.icon({
    iconUrl: '../assets/icon/hospital-solid-red.svg', 
    iconSize: [32, 32], 
    iconAnchor: [16, 32], 
    popupAnchor: [0, -32] ,

  });
  customIcon = L.icon({
    iconUrl: '../assets/icon/hospital-solid.svg', 
    iconSize: [32, 32], 
    iconAnchor: [16, 32], 
    popupAnchor: [0, -32] ,

  });

  locationIcon = L.icon({
    iconUrl: '../assets/icon/standort.png', 
    iconSize: [32, 32], 
    iconAnchor: [16, 32], 
    popupAnchor: [0, -32] ,

  });

  map:any;
  isLoading:boolean=false;
  mapSelection:boolean=false;
  MarkerArray:any[]=[];
  diseases: Disease[] = [];
  radiusSelection: boolean = false;
  locationMarker: L.Marker<any> | undefined;
  selectedPop: any = 
  {
    pop_name: ''
  };
  selectedHospital: Hospital | undefined ;
modus: any;
radius: any;

  constructor(
    private hospitalService:HospitalService,
    private routingservice: RoutingserviceComponent,
    public dialog: MatDialog,
    private regioService: RegioService
  )
  {}

  async ngOnInit(): Promise<void> {
    this.initMap();
    await this.getHospitals()
    this.diseases = this.hospitalService.getDiseases()
    this.setLayer(1)
  }
  openKrankenhaus(krankheiten: any[]) {
    this.mapSelection = false;
    const dialogRef = this.dialog.open(KrankenhausDialogComponent, {
      data: { krankenhaus: this.MarkerArray , krankheiten: krankheiten },
      width: '80vw',
      height: '70vh',
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result.mapSelection) {
        this.mapSelection = true
      }

      if (result.radiusSelection) {
        this.radiusSelection = true
      }
    });}
  openKrankheiten() {
    const dialogRef = this.dialog.open(KrankheitenDialogComponent, {
      data:  { krankheiten: this.diseases  },
      width: '1200px',
      height: '800px',
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        console.log(result)
        this.openKrankenhaus(result);
      }
    });}
    
    openUmkreis(marker: any, hospital: any) {
      marker.setIcon(this.customIconRed);

    this.selectedHospital = hospital
   }



    updateNext(event: Event) {
      const target = event.target as HTMLInputElement;
      this.currentYear = parseInt(target.value);
      
    
    
    }
    getColorForTicks(year: number): any {
    if(year == this.currentYear) return 'red'
    return 'black'}
    async openPop() {

      var pop = await this.routingservice.getPopulation();
      const dialogRef = this.dialog.open(PopDialogComponent, {
        data:  { krankheiten: this.diseases , pop: pop },
        width: '1200px',
        height: '800px',
      });
      dialogRef.afterClosed().subscribe(async (result: any) => {
        if (result.pop) {
          //alert(result.pop)
          this.selectedPop =result.pop
        }
      });}

  private initMap(): void {
    this.map = L.map('map', {
      center: [51.943889, 11.433056],
      zoom: 7,
    });

 
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);

    this.map.on('click', (e: any) => {
      if(!this.radiusSelection) return
      
            const lat = e.latlng.lat;
            const lng = e.latlng.lng;
           this.locationMarker = L.marker([lat, lng], { draggable: true, icon: this.locationIcon }).addTo(this.map)
           this.locationMarker.on('dragend', () => {alert('ha')})

     // L.marker([lat, lng]).addTo(this.map).bindPopup("Marker bei Klick").openPopup();


    })
  }
  async setLayer(level: number) {
    var l =  await this.regioService.getShapefileByIdAndLevel('15',level);

    //@ts-ignore
    l[0].forEach((layer : any)=> {
  
      var lay = L.geoJSON(layer.geometry,
        {
          style: {
            fillColor: 'green',
            fillOpacity: 0.2,
  
            color: "black",
            weight: 0.3
          }
        })
    
     
      lay.bindPopup("<b> " + layer.Object_Nam + "</b>")
      lay.on('click', () => {
      //  this.selectedUmriss = layer
     //   const layerIndex = this.selectedUmrisse.indexOf(layer)
  

        lay.setStyle({
          fillColor: 'green',
          fillOpacity: 0.2,
  
          color: "black",
          weight: 0.3
        });
  
        //this.selectedUmrisse.splice(layerIndex, 1);
  
  
      
      })
   //   this.umrisse.push(lay)
     
     
    this.map.addLayer(lay)
      
    });
  
  
    
  }
  
  async getHospitals(): Promise<void> {
    const hospitals: { success: boolean; data: Hospital[] } = await this.hospitalService.getHospitals();
      console.log("HH", hospitals);
  
     
  
      hospitals.data.forEach((hospital: Hospital) => {
        if (hospital.geometry.type === 'Point') {
          const [lng, lat] = hospital.geometry.coordinates;



          const popupContent = `
          <style>
             .popup-button {
              padding: 8px 16px;
              border: none;
              border-radius: 4px;
              font-size: 14px;
              cursor: pointer;
              margin-right: 8px;
            }
         
            .warn-button {
              background-color: #f44336;
              color: white;
              margin: 3px;
            }
        
          </style>
          <div class="popup-container">


          <b>${hospital.name}</b>
            <br><b>Betten:</b> ${hospital.anzahl_betten_total}
              
                    
                        

            <div>
              <button id="noButton" class="popup-button warn-button">Umkreissuche </button>
     
            </div>
          </div>
        `;
          
          let marker = L.marker([lat, lng], { icon: this.customIcon })
          .addTo(this.map)
          .bindPopup(popupContent);

          var obj = {
            leafletMarker:marker,
            raw: hospital,
            checked: false
          }

          marker.on("click", () => {if(this.mapSelection) {obj.checked = true
            marker.setIcon(this.customIconRed);
          }})

          marker.on('popupopen', () => { 
            document.querySelector('.leaflet-popup-content')?.querySelector('#noButton')?.addEventListener('click', () => { 

             this.openUmkreis(marker, hospital)
            })})



          this.MarkerArray.push(obj)
        }
      });
  }


  updateMarkerAsSelect(){
    var checkedArrays = this.MarkerArray.filter(el =>  el.checked )
    checkedArrays.forEach(element => {
      element.leafletMarker
      
    });



  }
} 
