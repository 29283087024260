
<div style="display: flex; flex-direction: column;">
<div>

    <re-chart  [chartWidth]="1200" [chartHeight]="800"> </re-chart>
        
    </div>

<div class="container">
    <h2> Enter your data body </h2>
  
    <textarea [(ngModel)]="dataText" rows="5" cols="40" placeholder="Enter your text here..."></textarea>
  



    <h2> Enter your statements here </h2>
  
    <textarea [(ngModel)]="userText" rows="5" cols="40" placeholder="Enter your text here..."></textarea>
  

  </div>

<button (click)="sendMessage()"> Start </button>

</div>

