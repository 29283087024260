import { Component, ViewChild } from '@angular/core';
import { AiServiceComponent } from '../re-chart/ai-service/ai-service.component';
import { ReChartComponent } from '../re-chart/re-chart.component';

@Component({
  selector: 'app-test2',
  templateUrl: './test2.component.html',
  styleUrls: ['./test2.component.css']
})
export class Test2Component {
  @ViewChild(ReChartComponent) chartComponent: ReChartComponent | undefined;

  userText: string = 'Give me a line chart for this data' // Initialize the variable to bind with textarea
  dataText: string = 'Data: 1,2,5,6,7 and labels are 2020, 2021, 2022, 2023, 2024, 2025'; // Initialize the variable to bind with textarea
  chartConfig: any; 

  constructor(private aiService: AiServiceComponent) {
    
  }


  async sendMessage() {
    var msg = 'Data is ' + this.dataText + '. Instruction is: ' + this.userText
    let cf = await this.aiService.sendMessage(msg);
    var chartConfig = JSON.parse(cf)


  this.chartComponent?.updateChart(chartConfig)
  }
}
