import { Component, ElementRef, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { ReMapComponent } from '../re-map/re-map.component';
import { RegioService } from '../service/regioservice/regioservice.component';
import { PopupComponent } from '../re-map/popup/popup.component';
import { HospitalService } from '../service/hospitalService/hospital.service';
import L from 'leaflet';
import { HospitalPopupComponent } from '../re-map/hospital-popup/hospital-popup.component';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent {
  @ViewChild(ReMapComponent)

  remap!: ReMapComponent;  // Zugriff auf die Kindkomponente

  customIconRed = L.icon({
    iconUrl: '../assets/icon/hospital-solid-red.svg', 
    iconSize: [32, 32], 
    iconAnchor: [16, 32], 
    popupAnchor: [0, -32] ,

  });
  customIcon = L.icon({
    iconUrl: '../assets/icon/hospital-solid.svg', 
    iconSize: [32, 32], 
    iconAnchor: [16, 32], 
    popupAnchor: [0, -32] ,

  });

  locationIcon = L.icon({
    iconUrl: '../assets/icon/standort.png', 
    iconSize: [32, 32], 
    iconAnchor: [16, 32], 
    popupAnchor: [0, -32] ,

  });
  mapp = false;

  //
  constructor(private regioService: RegioService, private hospitalService: HospitalService) {

  }

  async test() {

    const hospitals: { success: boolean; data: any[] } = await this.hospitalService.getHospitals();
    console.log("HH", hospitals);

   

    hospitals.data.forEach((hospital: any) => {
      this.remap.addMarker(hospital.name, hospital.geometry.coordinates[1], hospital.geometry.coordinates[0], hospital, this.customIcon, HospitalPopupComponent); 
    }) 




    
    let l = await this.regioService.getShapefileByIdAndLevel('15', 3);

    //@ts-ignore
    l[0].forEach((layer: any) => {

      let popup = "<b> " + layer.Object_Nam + "</b>"

   
      this.remap.addLayer( layer.Object_Nam , layer, PopupComponent,  this.remap.layerHighlightStyle)
    });

  }
  someFunction(event: any) {
    alert('Button 1 clicked: ' + event)
 
  }
  markerClicked(event: any) {
    //this.remap.focusMapOnId(event.id)

  }

  mapClicked(event: any) {

    this.remap.addMarker('pos', event.latlng.lat, event.latlng.lng, {'name': 'test=marker'}, this.remap.locationIcon, undefined, true)
  }

  dragEnded(event: any) {
    //alert('dragended for' + event.id + ' at ' + event.latlng)
  if(event.id == 'pos')
    this.remap.updateMarker(event.id, event.latlng.lat,  event.latlng.lng, undefined, this.customIconRed, HospitalPopupComponent, false)
 
  }

  makeMarkerDrag(event: any) {
  
    this.remap.updateMarker(event ,    undefined,     undefined, undefined, this.remap.locationIcon, HospitalPopupComponent, true)
 
  }
  layerClick(event: any) {
    alert('you clicked a layer, novv might change the style?')
    this.remap.focusMapOnId(event.id)

  }


  async getHospitals(): Promise<void> {
    const hospitals: { success: boolean; data: any[] } = await this.hospitalService.getHospitals();
      console.log("HH", hospitals);
  
     
  
      hospitals.data.forEach((hospital: any) => {
        if (hospital.geometry.type === 'Point') {
          const [lng, lat] = hospital.geometry.coordinates;

/*

          const popupContent = `
          <style>
             .popup-button {
              padding: 8px 16px;
              border: none;
              border-radius: 4px;
              font-size: 14px;
              cursor: pointer;
              margin-right: 8px;
            }
         
            .warn-button {
              background-color: #f44336;
              color: white;
              margin: 3px;
            }
        
          </style>
          <div class="popup-container">


          <b>${hospital.name}</b>
            <br><b>Betten:</b> ${hospital.anzahl_betten_total}
              
            <div>
              <button id="noButton" class="popup-button warn-button">Umkreissuche </button>
     
            </div>
          </div>
        `;
          
          let marker = L.marker([lat, lng], { icon: this.customIcon })
          .addTo(this.map)
          .bindPopup(popupContent);

          var obj = {
            leafletMarker:marker,
            raw: hospital,
            checked: false
          }

          marker.on("click", () => {if(this.mapSelection) {obj.checked = true
         //   marker.setIcon(this.customIconRed);
          }})

          marker.on('popupopen', () => { 
            document.querySelector('.leaflet-popup-content')?.querySelector('#noButton')?.addEventListener('click', () => { 

             this.openUmkreis(marker, hospital)
            })})

*/

        }
      });
  } 
  remove() {

  this.remap.updateLayer('Altmarkkreis Salzwedel', undefined, undefined, this.remap.layerDefaultStyle)


   // this.remap.removeMarker('Altmark Klinikum Gardelegen')
  }
}
