import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hospital-popup',
  templateUrl: './hospital-popup.component.html',
  styleUrls: ['./hospital-popup.component.css']
})
export class HospitalPopupComponent {
  @Input() json: any | undefined;

}
