
<button (click)="mapp = !mapp ;remap.initMap()"> test </button>
<button  id='test' style="left: 450px" (click)="test()"> add layer </button>

<button  id='test' style="left: 650px" (click)="remap.removeAllLayers()"> remove marker </button>
<button  id='test' style="left: 750px" (click)="remap.removeAllMarkers()"> remove layers </button>



<button id='test' (click)="remap.initMap()"> gagabubu </button>


<re-map (clickOnBtn1)="someFunction($event)" (clickOnBtn2)="makeMarkerDrag($event)"  
(dragEndMarker)="dragEnded($event)"
(clickOnLayer)="layerClick($event)"
(clickOnMap) ="mapClicked($event)"
(clickOnMarker)="markerClicked($event)"> </re-map>
