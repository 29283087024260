import { Component, Input } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

interface FoodNode {
  name: string;
  route?: string;
  children?: FoodNode[];
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  route?: string;
}


const TREE_DATA: FoodNode[] = [
  {
    name: 'Datahub',
    route: 'datahub2',
    children: [
      {
        name: 'Synthetische Population',
        route: 'datahub2/data-synth',
        children: [
          { name: 'Synthetische Population', route: 'datahub2/data-synth/pop' },
          { name: 'Entwicklungsprognose starten', route: 'datahub2/data-synth/prognose' },
          { name: 'Entwicklungsprognosen vergleichen', route: 'datahub2/data-synth/comparison-prognose'},
        ]
      },
      {
        name: 'Regionalindikatoren nach Gebietseinheiten',
        route: 'datahub2/data-regio',
        children: [
          { name: 'Dashboards', route: 'dashboards' },
          { name: 'datahub-ressourcen modifizieren', route: 'datahub-resourcen-modifizieren' },
        ]
      },
      { name: 'Einrichtungen der Daseinsvorsorge', route: 'datahub2/docs' },
      { name: 'Administrative Verwaltungseinheiten', route: 'datahub2/gebiete' },
      {
        name: 'Mobilitätsnetzwerke',
        route: 'networks',
        // children: [
        //   { name: 'Straßenetzwerke', route: 'networks' },
        //   { name: 'Szenarien', route: 'szenarien' },
        // ]
      },
    ]
  },
  {
    name: 'Prognosen',
    route: 'prognosen',
    children: [
      { name: 'Schulentwicklungsplanung', route: 'prognosen/schule' },
      { name: 'Nachfrageberechnung Medizinische Infrastuktur', route: 'prognosen/nachfrageberechnung' },
      { name: 'Hitzehotspots', route: 'prognosen/hitzehotspots' },
    ]
  },
  {
    name: 'Analysen',
    route: 'analysen',
    children: [
      { name: 'Analyse Notfallinfrastruktur', route: 'analysen/notfallinfrastruktur' },
      { name: 'Erreichbarkeitsanalysen', route: 'analysen/reachability' },
      { name: 'Krankenhausreform und -atlas', route: 'analysen/Hospital' },
    ]
  },
  {
    name: 'Simulation',
    route: 'simulation-section',
    children: [
      { name: 'Hitzesimulation', route: 'simulation-section/hitzesimulation' },
      {
        name: 'Virusinfektion',
        route:'simulation-section/virusinfektion',
        children: [
          {
            name: 'Covid-19',
            route:'simulation-section/virusinfektion/covid',
            children: [
              { name: 'Basis Szenario', route: 'simulation-section/virusinfektion/covid/simulation' },
              { name: 'Laissez-faire', route: 'simulation-section/virusinfektion/covid/laissez-faire' },
              { name: 'Maskenpflicht', route: 'simulation-section/virusinfektion/covid/maskenpflicht' },
              { name: 'Vakzinierung', route: 'simulation-section/virusinfektion/covid/vakzinierung' },
              { name: 'Eigenes Szenario erstellen', route: 'simulation-section/virusinfektion/covid/eigenes-szenario' },
            ]
          },
          { name: 'Influenza', route: 'simulation-section/virusinfektion/influenza' },
        ]
      },
      { name: 'Katastrophenschutz', route: 'simulation-section/katastrophenschutz' },
      { name: 'Energie', route: 'simulation-section/energie' },
      { name: 'Mobilität', route: 'szenarien' },
    ]
  },
  {
    name: 'Benachrichtigungen',
    route: 'nachrichten'
  }
];


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent {
  @Input() drawer!: any;


  private _transformer = (node: FoodNode, level: number): ExampleFlatNode => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      route: node.route
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  selectedNode: ExampleFlatNode | null = null;

  constructor(private router: Router) {
    this.dataSource.data = TREE_DATA;
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  onNodeClick(node: ExampleFlatNode): void {

    if (node.route) {
      this.router.navigateByUrl(node.route);
      this.drawer.close();
      this.selectedNode = node;
    }

  }

  isSelected(node: ExampleFlatNode) {
    return this.selectedNode === node;
  }


}