import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-umkreis-dialog',
  templateUrl: './umkreis-dialog.component.html',
  styleUrls: ['./umkreis-dialog.component.css']
})
export class UmkreisDialogComponent {
  @Input() selectedHospital: any;
radius: any;
modus: any;
openPop() {
throw new Error('Method not implemented.');
}

  start() {
   var b =  {
      "body": {
        "radius": 6000,
        "unit": "m",
        "id": "9df99701a2f3bf80abc",
        "object_types": [
          "Wohnhaus"
        ],
        "hospitals": [
          {
            "ambulant_fallzahlen_total": 161113,
            "anzahl_betten_total": 911,
            "anzahl_fachabteilungen": 27,
            "Object_Key": "15001",
            "geometry": {
              "type": "Point",
              "coordinates": [
                11.9366,
                51.5016
              ]
            },
            "name": "Universitätsklinikum Halle (Saale)",
            "only_day_clinic": false,
            "stationaer_fallzahlen_total": 34826
          }
        ]
      }
    }
  }

}
