import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pop-dialog',
  templateUrl: './pop-dialog.component.html',
  styleUrls: ['./pop-dialog.component.css']
})
export class PopDialogComponent {
startNewScenario() {
throw new Error('Method not implemented.');
}
checkDate(arg0: any): any {
return true
}
setExistingScenario(p: any) {

  this.dialogRef.close({pop: p});

}
translateUserName(arg0: any) {
return ''}

  pop: any[] = []
newExtended: any;
docPopID: any;
newName: any;
searchExtended: any = true;
searchBox: any = '';
isRente: any;
rentenAlter: any;
  
  constructor(
    public dialogRef: MatDialogRef<PopDialogComponent>,

    @Inject(MAT_DIALOG_DATA) private data: any    
    ) {
      this.pop = data.pop
      console.log(this.pop)


    }


}
