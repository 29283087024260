export const GlobalVariable = Object.freeze({
    DATAHUB_URL:   'https://datahubservice.cephlabs.de',
    ROUTING_URL: 'https://api.cephlabs.de',
    VORSORGE_URL: 'https://s1.api.cephlabs.de',
    LLAMA_URL: 'https://api.ai.bunsencloud.de/api/chat',
    YEAR: 2025

   
  
});
