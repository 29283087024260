import { Component } from '@angular/core';
import { LlamaComponent } from 'src/app/service/llama/llama.component';

@Component({
  selector: 'app-ai-service',
  templateUrl: './ai-service.component.html',
  styleUrls: ['./ai-service.component.css']
})
export class AiServiceComponent {
  messages: any[] = [];


  constructor( private llamaService: LlamaComponent) {
    var stringtest = `Your task is it to convert an json input into a chart js json configuration. Here is a sample of this config data:' 
      {
        "type": "bar",
        "data": {
          "labels": ["January", "February", "March", "April", "May", "June"],
          "datasets": [
            {
              "label": "Sales",
              "data": [65, 59, 80, 81, 56, 55],
              "backgroundColor": "rgba(77, 83, 96, 0.2)",
              "borderColor": "rgba(77, 83, 96, 1)",
              "borderWidth": 1
            }
          ]
        },
        "options": {
          "responsive": true,
          "scales": {
            "x": {
              "beginAtZero": true
            },
            "y": {
              "beginAtZero": true
            }
          }
        }
      }. Anser only ith the config json. make sure the json is valid. it is forbidden to say anything else.`
    this.messages.push({
      content: stringtest,
      role: 'system'
    })  }

  async sendMessage(msg: string) {

    this.messages.push({
      content: msg ,
      role: 'user'
    })

  var t =  await this.llamaService.sendMessages(this.messages)
    //@ts-ignore

  this.messages.push(t.message)
    //@ts-ignore

  return t.message.content


}

}
