<h1> Umkreissuche  </h1>

<span>
    Umgebung von {{ selectedHospital.name }} im  Umkreis von </span>
  <input placeholder="500" [(ngModel)]="radius">
  <select matNativeControl required [(ngModel)]="modus">
    <option value="Distance">Meter</option>
    <option value="Time">Minuten (Fahrzeit) </option>

  </select>
  <span> bestimmen  </span>
  <br>
  <br>


  
  <button class="btn" (click)="openPop()"> Analyse starten
  </button>