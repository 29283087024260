<div >


    <b>{{json.name}}</b>
      <br><b>Betten:</b> {{json.anzahl_betten_total}}
        
              
                  

      <div>
        <button class="btn" id="btn1" >Click me</button>
        <button class="btn" id="btn2" >Click m2e</button>

      </div>
    </div>