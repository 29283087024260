import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChartOptions, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { AiServiceComponent } from './ai-service/ai-service.component';

interface ChartConfig {
  type: ChartType;
  data: {
    labels: string[];
    datasets: Array<{
      label: string;
      data: number[];
      backgroundColor?: string | string[];
      borderColor?: string | string[];
      borderWidth?: number;
    }>;
  };
  options?: {
    responsive?: boolean;
    scales?: any;
    animation?: any;
    elements?: any;
  };
}

@Component({
  selector: 're-chart',
  templateUrl: './re-chart.component.html',
  styleUrls: ['./re-chart.component.css']
})
export class ReChartComponent implements AfterViewInit {
  @ViewChild('chartCanvas') chartCanvas: ElementRef | undefined;
  
  @Input() chartWidth: number = 600;  // Input for chart width
  @Input() chartHeight: number = 400; // Input for chart height
  @Input() chartConfig : ChartConfig = {
    "type": "bar",
    "data": {
      "labels": ["January", "February", "March", "April", "May", "June"],
      "datasets": [
        {
          "label": "Sales",
          "data": [65, 59, 80, 81, 56, 55],
          "backgroundColor": "rgba(77, 83, 96, 0.2)",
          "borderColor": "rgba(77, 83, 96, 1)",
          "borderWidth": 1
        }
      ]
    },
    "options": {
      "responsive": true,
      "scales": {
        "x": {
          "beginAtZero": true
        },
        "y": {
          "beginAtZero": true
        }
      }
    }
  }

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;




 isValidChartConfig(chartConfig: ChartConfig): boolean {
  const validChartTypes: ChartType[] = ['line', 'bar', 'radar', 'pie', 'doughnut', 'polarArea'];

  // 1. Check for valid type
  if (!chartConfig.type || !validChartTypes.includes(chartConfig.type)) {
    console.error('Invalid chart type:', chartConfig.type);
    return false;
  }

  // 2. Check for required data properties
  if (!chartConfig.data || !Array.isArray(chartConfig.data.labels) || chartConfig.data.labels.length === 0) {
    console.error('Invalid or missing data labels');
    return false;
  }
  if (!chartConfig.data.datasets || chartConfig.data.datasets.length === 0) {
    console.error('Invalid or missing datasets');
    return false;
  }

  // 3. Check each dataset for required properties
  for (let dataset of chartConfig.data.datasets) {
    if (!dataset.label || !Array.isArray(dataset.data) || dataset.data.length === 0) {
      console.error('Invalid dataset:', dataset);
      return false;
    }
  }

  // 4. Optionally validate other properties in options
  if (chartConfig.options) {
    if (chartConfig.options.responsive && typeof chartConfig.options.responsive !== 'boolean') {
      console.error('Invalid responsive option');
      return false;
    }
    if (chartConfig.options.scales) {
      // Validate scales structure if necessary (can be customized for each chart type)
      if (typeof chartConfig.options.scales !== 'object') {
        console.error('Invalid scales structure');
        return false;
      }
    }
  }

  // If all checks pass, return true
  return true;
}




  constructor() {}
  ngAfterViewInit(): void {
    if(this.chartCanvas) {


      //this.chartCanvas.nativeElement.width = this.chartWidth;
        //  this.chartCanvas.nativeElement.height = this.chartHeight;
 }  }


  updateChart(newConfig: any) {
    this.chartConfig = newConfig;

   if(this.chart?.chart) {

    this.chart.chart.update();
   } 


  }

  // Methode zum Umgang mit dem Click-Ereignis auf dem Diagramm
  public async chartClicked(event: any): Promise<void> {


  return;
/*

    // Überprüfe, ob die Chart-Konfiguration geladen ist
    if (!this.chartConfig || !this.chart) {
      return;
    } 
    // Holen Sie sich die Klick-Positionen des Benutzers
    console.log(event)
    let activePoints:  any[] = []

    if(this.chart.chart) {
     activePoints = this.chart.chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
    }
   // alert(activePoints.length)
    if (activePoints.length > 0) {
      // Wenn ein Dataset oder ein Punkt geklickt wurde, erhalten wir das erste Element
      const clickedElement = activePoints[0];
      
      // Ermitteln Sie das Dataset, das angeklickt wurde
      const datasetIndex = clickedElement.datasetIndex; // Index des Datasets
      const datasetLabel = this.chartConfig.data.datasets[datasetIndex].label;  // Das Label des Datasets

      // Ermitteln Sie den Label-Wert (X-Achse)
      const labelIndex = clickedElement.index; // Index des Labels (X-Achse)
      const label = this.chartConfig.data.labels[labelIndex]; // Label-Name

      // Ermitteln Sie den Wert, der angeklickt wurde
      const value = this.chartConfig.data.datasets[datasetIndex].data[labelIndex];  // Der Wert des Datenpunkts

      console.log('Dataset Label:', datasetLabel);
      console.log('Label:', label);
      console.log('Value:', value);

      alert('Dataset Label:' + datasetLabel +
      ' Label:' + label +
      ' Value:'+ value)

      // Wenn Sie weitere Aktionen basierend auf dem Klick durchführen möchten, können Sie dies hier tun.
    }*/
  }


  }




