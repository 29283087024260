<app-loading-overlay *ngIf="isLoading"></app-loading-overlay>

 



<div class="map-container" style="height: 100%">
    <div class="map" id="map"></div>
</div> 

<button class="btn" style="top: 80px; width: 250px" (click)="openKrankheiten()"> Spezialisierungsanalyse starten </button> 




<button class="btn" style="top: 160px; width: 250px" (click)="openPop()">
    <ng-container  *ngIf="!selectedPop.pop_name">
        Population auswählen

    </ng-container>

    <ng-container *ngIf="selectedPop.pop_name">
        Population:   {{ selectedPop.pop_name }}

    </ng-container>
     </button> 


     <div id="umkreis-container" *ngIf="selectedHospital">
        <app-umkreis-dialog [selectedHospital]="selectedHospital"> </app-umkreis-dialog>
  
     </div>

<div id="playContainer">
  


    <div id="slide-container">

        <input type="range" id="customRange1" value="1" min="2024" max="2034"
            (change)="updateNext($event)" />

        <div class="ticks">


            <ng-container *ngFor="let e of [].constructor(11);
    let i = index;">

                <span class="tick" [ngStyle]="{'color': getColorForTicks(i) }"> {{ 2025 + i }} </span>
            </ng-container>


        </div>

    </div>

    <button class="zoomBtn" >

        <i  [ngClass]=" 'bi bi-trash'"></i>
    </button>

    <button class="zoomBtn" >

        <i  [ngClass]=" 'bi bi-trash'"></i>
    </button>

    <button class="zoomBtn">


        <i [ngClass]=" 'bi bi-floppy-fill'"></i>
    </button>

</div>
 