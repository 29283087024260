<app-error-dialog [needDialog]="needDialog" [dialogTitle]="dialogTitle" [dialogMessage]="dialogMessage"
  (needDialogChange)="needDialog = $event" (dialogClosed)="openDialogSave()">
</app-error-dialog>


<div *ngIf="loadingN" class="loader-overlay">
  <mat-spinner class="loader"></mat-spinner>
  <span>Daten Werden geladen ...</span>
</div>
<div class="folder-sticker">
  <span class="mainDatahub">Datahub.Regionalindikatoren nach Gebietseinheiten.</span>
</div>


<div class="page">
  <div class="row1" id="mapChart">
    <div id="map-container">
      <div *ngIf="spinner">
        <mat-spinner></mat-spinner>
      </div>
      <div class="map" id="map2" leaflet [leafletOptions]="options" [leafletLayers]="layers"
        (leafletMapReady)="onMapReady($event)">
        <div *ngIf="selectedChartObject" class="sliderDes">
          <button class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab (click)="unbindFromMap()"
            (mouseenter)="showDoNotApplyOnMap=true" (mouseleave)="showDoNotApplyOnMap=false">
            <mat-icon>link_off</mat-icon>
            <span *ngIf="showDoNotApplyOnMap"
              style="position: absolute; top: 10px; right:50px ; padding: 4px;color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
              Karte an Graph entkoppeln
            </span>
          </button>


        </div>
        <div *ngIf="selectedChartObject && !chartApplyOnMap" class="sliderDes">

          <button class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab
            (click)="openDialogYear()" (mouseenter)="showTimeAndValue=true" (mouseleave)="showTimeAndValue=false">
            <mat-icon>filter_alt</mat-icon>
            <span *ngIf="showTimeAndValue"
              style="position: absolute; top: 10px; right:50px ; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
              Jahr und Wertebereich ändern
            </span>
          </button>

          <button class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab
            (click)="openDialogBreaks()" (mouseenter)="showBreaks=true" (mouseleave)="showBreaks=false">
            <mat-icon> rule</mat-icon>
            <span *ngIf="showBreaks"
              style="position: absolute; top: 10px; right:50px ; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
              Legende bearbeiten
            </span>
          </button>

          <button class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab
            (click)="openDialogColor()" (mouseenter)="showColor=true" (mouseleave)="showColor=false">
            <mat-icon>color_lens</mat-icon>
            <span *ngIf="showColor"
              style="position: absolute; top: 10px; right:50px ; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
              Farbschema ändern
            </span>
          </button>

          <!-- <button class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab (mouseenter)="showMapDownload=true" (mouseleave)="showMapDownload=false" >
              <mat-icon>save_alt</mat-icon>
              <span *ngIf="showMapDownload" style="position: absolute; top: 10px; right:50px ; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
                Karte unterladen
              </span>
            </button> -->

          <button class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab
            (click)="openDialogRegion()" (mouseenter)="showFilterRegion=true" (mouseleave)="showFilterRegion=false">
            <mat-icon>deselect</mat-icon>
            <span *ngIf="showFilterRegion"
              style="position: absolute; top: 10px; right:50px ; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
              Region Filtern
            </span>
          </button>

          <button class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab (click)="bindToMap()"
            (mouseenter)="showApplyOnMap=true" (mouseleave)="showApplyOnMap=false">
            <mat-icon>link</mat-icon>
            <span *ngIf="showApplyOnMap"
              style="position: absolute; top: 10px; right:50px ; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
              Karte an Graph koppeln
            </span>
          </button>





        </div>
      </div>
    </div>




    <div id="chart-container">

      <div [hidden]="newSelection">

        <div
          style=" display: flex; justify-content: center; align-items: center; gap: 20px; margin-top:10px; flex-direction:column; max-width: 230px;">
          <button id="stratDatahub1" *ngIf="!selectedChartObject" (click)="datahubPath=1;showModal(0); editPen=true"
            mat-raised-button color="primary">Neues Dashboard erstellen</button>
          <button id="stratDatahub2" *ngIf="!selectedChartObject" mat-raised-button color="primary"
            (click)="datahubPath=2;showModal(0);customData=true;customPath=true; editPen=false">Externe Datensätze in
            den Datahub importieren</button>
          <button id="stratDatahub3" *ngIf="!selectedChartObject" mat-raised-button color="primary"
            (click)="openDialogGetDash()">Gespeichertes Dashboard laden</button>
          <!-- <div style="border:1px solid black; width: 350px ; height:500px; border-radius:15px;  ">
                    Sample-Dashboards
                  </div> -->
          <!-- <button mat-raised-button color="primary"(click)="testIntro()">Tour starten</button> -->
        </div>


      </div>


      <div [hidden]="!newSelection">
        <h3 id="testTab" style="display: flex; gap:4px; justify-content: center;" *ngIf="selectedChartObject"> {{
          selectedChartObject.Name }} <mat-icon (click)="showInfo(selectedChartObject)">info</mat-icon> </h3>

        <mat-tab-group #firstMatGroup>
          <mat-tab label="Chart Type">
            <div *ngIf="selectedChartObject" class="selection">
              <mat-button-toggle-group appearance="legacy" value="{{selectedChartIndex}}">
                <mat-button-toggle value="0" (click)="changeChartType(0)">Allgemein</mat-button-toggle>
                <mat-button-toggle value="1" (click)="changeChartType(1)">Jährliche Veränderungsrate</mat-button-toggle>
                <mat-button-toggle value="2" (click)="changeChartType(2)">Änderungsrate zwischen
                  Zeitpunkten</mat-button-toggle>
                <mat-button-toggle value="3" (click)="changeChartType(3)">Vergleich</mat-button-toggle>
              </mat-button-toggle-group>

            </div>
          </mat-tab>

          <mat-tab label="Einstellungen">
            <div *ngIf="selectedChartObject"
              style="display: flex; justify-content: space-between; align-items: center; width: 650px; height: 100px;">
              <ng-container *ngIf="selectedChartIndex < 2">
                <div>
                  Wertefilter: <mat-slider min="{{ minValue }}" max="{{ maxValue }}" discrete step="0.1"
                    [displayWith]="formatLabel">
                    <input matSliderStartThumb value="{{ minValue }}" (change)="filter($event, 'valMin')">
                    <input matSliderEndThumb value="{{ maxValue }}" (change)="filter($event, 'valMax')">
                  </mat-slider>
                </div>
              </ng-container>
              <div style="margin-left: 10px; ">
                Zeitstrahl:
                <mat-slider min="{{ minYear }}" max="{{ maxYear }}" step="1" showTickMarks discrete
                  [displayWith]="formatLabel">
                  <input matSliderStartThumb value="{{ minYear }}" (change)="filter($event, 'timeMin')">
                  <input matSliderEndThumb value="{{ maxYear }}" (change)="filter($event, 'timeMax')">
                </mat-slider>
              </div>
              <mat-button-toggle-group name="fontStyle" aria-label="Font Style" *ngIf="selectedChartIndex < 2">
                <mat-button-toggle (click)="changeType('line')">Line</mat-button-toggle>
                <mat-button-toggle (click)="changeType('bar')">Bar</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </mat-tab>

          <mat-tab label="Optionen">
            <div *ngIf="selectedChartObject"
              style="display: flex; justify-content: center;height: 80px; padding: 10px;width: 700px;gap:50px">
              <button (click)="openDialogShare()" class="mapIcons" style="background-color: #126F62;color: white"
                mat-mini-fab (mouseenter)="showShareChart=true" (mouseleave)="showShareChart=false">
                <mat-icon>share</mat-icon>
                <span *ngIf="showShareChart"
                  style="position: absolute;  bottom:-30px ; left: -85px; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
                  Dashboard teilen
                </span>
              </button>

              <button class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab
                (mouseenter)="showDownloadChart=true" (mouseleave)="showDownloadChart=false" (click)="showReport()">
                <mat-icon>save_alt</mat-icon>
                <span *ngIf="showDownloadChart"
                  style="position: absolute;  bottom:-30px ; left: -85px; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
                  als PDF herunterladen
                </span>
              </button>
              <button class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab
                (click)="openDialogData()" (mouseenter)="showTable=true" (mouseleave)="showTable=false">
                <mat-icon>dataset</mat-icon>
                <span *ngIf="showTable"
                  style="position: absolute;  bottom:-30px ; left: -85px; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
                  Daten als Tabelle anzeigen
                </span>
              </button>

              <button class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab
                (click)="openDialogSave()" (mouseenter)="showSave=true" (mouseleave)="showSave=false">
                <mat-icon>save</mat-icon>
                <span *ngIf="showSave"
                  style="position: absolute;  bottom:-30px ; left: -85px; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
                  Dashboard speichern
                </span>
              </button>

              <button *ngIf="checkVersionHistory" class="mapIcons" style="background-color: #126F62;color: white"
                mat-mini-fab (click)="openDialogVersion()" (mouseenter)="showVersion=true"
                (mouseleave)="showVersion=false">
                <mat-icon>manage_history</mat-icon>
                <span *ngIf="showVersion"
                  style="position: absolute;  bottom:-30px ; left: -85px; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
                  Version Historie
                </span>
              </button>

              <!-- <button  class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab (click)="bindToMap()" (mouseenter)="showApplyOnMap=true" (mouseleave)="showApplyOnMap=false" >
                            <mat-icon>link</mat-icon>
                            <span *ngIf="showApplyOnMap" style="position: absolute;  bottom:-30px ; left: -85px; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
                              Karte an Graph koppeln
                            </span>
                          </button>
                          <button class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab (click)="unbindFromMap()" (mouseenter)="showDoNotApplyOnMap=true" (mouseleave)="showDoNotApplyOnMap=false" >
                            <mat-icon>link_off</mat-icon>
                            <span *ngIf="showDoNotApplyOnMap" style="position: absolute;  bottom:-30px ; left: -85px; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
                              Karte an Graph entkoppeln
                            </span>
                          </button> -->
            </div>
          </mat-tab>
        </mat-tab-group>

        <div class="flexBox">

          <div style="height: 300px;width: 600px;" id="chart">
            <canvas id="canvasGeneral">
              {{ chartGeneral }}
            </canvas>
          </div>

          <div class="flexBox" style="flex-direction: column; gap: 10px;">

            <mat-form-field class="example-full-width">
              <input type="text" aria-label="Assignee" matInput [formControl]="myControl" [matAutocomplete]="auto"
                placeholder="Gebiet suchen">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="scrollToElement($event)">
                <mat-option *ngFor="let l of filteredOptions | async" [value]="l.label">
                  {{l.label}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <div id="chartLegend" class="selectable-legend">
              <div style="display: flex;" *ngFor="let l of chartLegend">
                <div [style.background-color]="l.backgroundColor" style="width:10px; height: 20px "> </div>

                <div style="margin-left: 5px;" (click)="filterRegion(l)" id="{{'legendItem-' + l.label}}">
                  <s *ngIf="l.disabled"> {{ l.label }} </s>
                  <span *ngIf="!l.disabled"> {{ l.label }} </span>
                </div>
              </div>
            </div>
            <div>
              <button button mat-raised-button color="primary" (click)="deselectAll(false)" *ngIf="selectMode"> Alle
                auswählen </button>
              <button button mat-raised-button color="primary" (click)="deselectAll(true)" *ngIf="!selectMode"> Alle
                abwählen </button>
            </div>
          </div>

        </div>

        <div style="display: flex; justify-content:center; align-items:center; gap:20px">
          <h5 style="margin-block-start: 0em; margin-block-end: 0em;">
            Quelle:
            <a class="source" [href]="'https://www.' + quelle + '/'" target="_blank">
              https://www.{{ quelle }}/
            </a>

          </h5>

          <button mat-button [ngClass]="chartApplyOnMap ? 'mat-primary' : 'mat-warn'">
            <mat-icon *ngIf="chartApplyOnMap">link</mat-icon>
            <mat-icon *ngIf="!chartApplyOnMap">link_off</mat-icon>
            {{ chartApplyOnMap ? 'Karte an Graph gekoppelt' : 'Karte an Graph nicht gekoppeln' }}
          </button>
          <button *ngIf="neighbours && neighbours.length == 0 && selectedLevel==3" mat-raised-button
            (click)="showNearbyGebiete()">Umliegende Gebiete anzeigen</button>

          <button *ngIf="neighbours && neighbours.length > 0 && selectedLevel==3" mat-button
            (click)="hideNearbyGebiete()">Umliegende Gebiete ausblenden</button>

        </div>


      </div>

    </div>
  </div>

  <app-indicator-radar #childComponent id="indicatorRadar" [indiForRadar]="activateRadar" [dataForRadar]="currentState"
    [regionSelectedInDialog]="selectedRegionForRadar" [radarYears]="radarYears"
    [radarChartIndex]="radarChartIndex"></app-indicator-radar>




  <div style="margin-top: 1%; margin-left: 1%; margin-bottom: 1%;">
    <div style="width: 98vw;" class="flexBox">
      <div class="ThemenIndiBox" *ngIf="auswahlEnded && !radarActivated">

        <div
          style="display: flex; justify-content:center; align-items:center;flex-direction:column;gap:10px; border-right:1px solid #ccc; padding-right:10px">
          <div style="display: flex; justify-content:center; align-items:center;width: 100%; gap:15px">
            <mat-icon *ngIf="selectedLevel!=3 && editPen" (click)="jumpToPreviousArea()"
              class="area-arrow">keyboard_double_arrow_left</mat-icon>
            <button mat-fab extended color="primary" *ngIf="selectedLevel==3" [disabled]='!editPen'> Landkreis</button>
            <button mat-fab extended color="primary" *ngIf="selectedLevel==4" [disabled]='!editPen'>
              Verbandsgemeinde</button>
            <button mat-fab extended color="primary" *ngIf="selectedLevel==5" [disabled]='!editPen'> Gemeinde</button>
            <mat-icon *ngIf="selectedLevel!=5 && editPen" (click)="jumpToNextArea()"
              class="area-arrow">keyboard_double_arrow_right</mat-icon>
          </div>
          <button *ngIf="editPen" mat-fab extended color="primary" (click)="showModal(1);startTopicForArea()">
            <mat-icon>edit</mat-icon> Thema/Indikator Bearbeiten </button>

        </div>

        <mat-tab-group style="width:60vw" #tabGroup>
          <div *ngIf="selectedLevel==3">
            <ng-container *ngFor="let topic of topicsLandkreis">
              <mat-tab *ngIf="topic.checked" [label]="topic.Bereich + ' (' + getCheckedCount(topic.data) + ')'">

                <ng-template mat-tab-label>
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <span>{{ topic.Bereich + ' (' + getCheckedCount(topic.data) + ')' }}</span>
                    <mat-icon style="cursor: pointer; margin-left: 8px;" (click)="openWarningDialog(topic)">
                      close
                    </mat-icon>
                  </div>
                </ng-template>

                <div *ngIf="inputIndi == ''"
                  style="display: flex; align-items:center; gap:8px; width:55vw; padding:8px; overflow-x:scroll">
                  <div *ngFor="let indi of topic.data; let i = index">
                    <button style="z-index: 1;" *ngIf="indi.checked && auswahlClicked" (click)="changeChart(indi)"
                      [ngClass]="{'mat-warn': selectedButton === indi}" mat-raised-button style="min-width: 250px;">
                      {{ indi.Name }}
                      <mat-icon style="z-index: 2;" (click)="removeIndi(indi,topic)">close</mat-icon>
                    </button>
                  </div>
                </div>
                <button *ngIf="inputIndi" mat-raised-button style="min-width: 250px;"> {{ inputIndi }}</button>
              </mat-tab>
            </ng-container>
          </div>
          <div *ngIf="selectedLevel==4">
            <ng-container *ngFor="let topic of topicsVerbandsgemeinde">
              <mat-tab *ngIf="topic.checked" [label]="topic.Bereich + ' (' + getCheckedCount(topic.data) + ')'">
                <div *ngIf="inputIndi == ''"
                  style="display: flex; align-items:center; gap:8px; width:55vw; padding:8px; overflow-x:scroll">

                  <ng-template mat-tab-label>
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                      <span>{{ topic.Bereich + ' (' + getCheckedCount(topic.data) + ')' }}</span>
                      <mat-icon style="cursor: pointer; margin-left: 8px;" (click)="openWarningDialog(topic)">
                        close
                      </mat-icon>
                    </div>
                  </ng-template>

                  <div *ngFor="let indi of topic.data; let i = index">
                    <button style="z-index: 1;" *ngIf="indi.checked && auswahlClicked" (click)="changeChart(indi)"
                      [ngClass]="{'mat-warn': selectedButton === indi}" mat-raised-button style="min-width: 250px;">
                      {{ indi.Name }}
                      <mat-icon style="z-index: 2;" (click)="removeIndi(indi,topic)">close</mat-icon>
                    </button>
                  </div>
                </div>
                <button *ngIf="inputIndi" mat-raised-button style="min-width: 250px;"> {{ inputIndi }}</button>
              </mat-tab>
            </ng-container>
          </div>
          <div *ngIf="selectedLevel==5">
            <ng-container *ngFor="let topic of topicsGemeinde">
              <mat-tab *ngIf="topic.checked" [label]="topic.Bereich + ' (' + getCheckedCount(topic.data) + ')'">

                <ng-template mat-tab-label>
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <span>{{ topic.Bereich + ' (' + getCheckedCount(topic.data) + ')' }}</span>
                    <mat-icon style="cursor: pointer; margin-left: 8px;" (click)="openWarningDialog(topic)">
                      close
                    </mat-icon>
                  </div>
                </ng-template>

                <div *ngIf="inputIndi == ''"
                  style="display: flex; align-items:center; gap:8px; width:55vw; padding:8px; overflow-x:scroll">
                  <div *ngFor="let indi of topic.data; let i = index">
                    <button style="z-index: 1;" *ngIf="indi.checked && auswahlClicked" (click)="changeChart(indi)"
                      [ngClass]="{'mat-warn': selectedButton === indi}" mat-raised-button style="min-width: 250px;">
                      {{ indi.Name }}
                      <mat-icon style="z-index: 2;" (click)="removeIndi(indi,topic)">close</mat-icon>
                    </button>
                  </div>
                </div>
                <button *ngIf="inputIndi" mat-raised-button style="min-width: 250px;"> {{ inputIndi }}</button>
              </mat-tab>
            </ng-container>
          </div>
          <mat-tab *ngIf="inputThema" [label]="inputThema">
            <div style="display: flex; align-items:center; gap:8px; width:55vw; padding:8px; overflow-x:scroll">
              <button *ngIf="inputIndi" mat-raised-button style="min-width: 250px;" color="warn"> {{ inputIndi
                }}</button>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>

      <div *ngIf=" radarActivated &&auswahlEnded && selectedChartIndex < 2 && datahubPath==1" class="ThemenIndiBox" 
        style="display:flex; justify-content:center; align-items:center;width: 98vw; height:120px; border-radius:8px;border:2px solid #ccc; flex-direction:column; gap:8px; padding:10px;">

        <div *ngIf="radarActivated"
          style="font-size: 14px; width:100%; display:flex; justify-content:center; align-items:center; flex-direction:column; gap:8px; border-top:1px solid #ccc; padding-top:8px">
          <button mat-raised-button [color]="lastClickedAction === 'Allgemein' ? 'warn' : 'primary'"
            (click)="changeChartRadar(0)">Allgemein</button>
          <button mat-raised-button [color]="lastClickedAction === 'Jährliche Veränderungsrate' ? 'warn' : 'primary'"
            (click)="changeChartRadar(1)">Prozentuale Veränderung Vergleich Vorjahr</button>
        </div>


      </div>

      <div *ngIf="auswahlEnded && selectedChartIndex < 2 && datahubPath==1" class="flexBox"
        style="display:flex; justify-content:center; align-items:center;width: 15vw; height:120px; border-radius:8px;border:2px solid #ccc; flex-direction:column; gap:8px; padding:10px;">
        <div style="width:100%; display:flex; justify-content:center; align-items:center;gap:8px">
          <button mat-stroked-button (click)="openDialogRadar()">Indikatoren Radar</button>
          <button mat-stroked-button (click)="displayMapChart()">Dashboard</button>
        </div>

      </div>




    </div>
  </div>

</div>

<dialog id="dialog" *ngIf="datahubPath==1">
  <div style="width: 1200px; ">

    <button style="margin-left:96.8%; background-color: #10684E;color: white" mat-mini-fab (click)="close()">
      <mat-icon>close </mat-icon>
    </button>
    <br>
    <br>
    <div *ngIf="showAnal">



      <span class="span1"> Schritt 1: Auswahl Regionalebene </span>
      <br>
      <br>
      <span class="span2"> Wählen Sie im ersten Schritt die für Sie relevante Analysebene aus. </span>
      <br>
      <br>

      <mat-radio-group [(ngModel)]="selectedLevel" #radioGroup="matRadioGroup">
        <mat-radio-button value=3 (click)="land=3"> Landkreis</mat-radio-button>
        <mat-radio-button value=4 (click)="land=4"> Verbandsgemeinde</mat-radio-button>
        <mat-radio-button value=5 (click)="land=5"> Gemeinde</mat-radio-button>

      </mat-radio-group>

      <div style="display: flex; justify-content: end;">
        <span style="margin-right: 0; " class="span2"> Zur Themen-Auswahl
          <button mat-mini-fab (click)="startTopic2()" style="background-color: #10684E; color: white;">
            <mat-icon>arrow_forward_ios </mat-icon>
          </button>
        </span>
      </div>
    </div>

    <br>
    <br>

    <div *ngIf="showDetail && !showIndi">

      <span class="span1"> Schritt 2: Auswahl Indikatoren </span>
      <br>
      <br>
      <span class="span2"> Wählen Sie im zweiten Schritt die für Sie relevanten Themen aus. </span>
      <br> <br> <br> <br>
      <div *ngIf="topicsLoaded">
        <mat-spinner></mat-spinner>
      </div>

      <div *ngIf="!topicsLoaded">
        <span *ngIf="regioMetaData.length == 0" class="span2"> Keine Themen verfügbar </span>
        <div *ngIf="regioMetaData.length != 0"
          style="display: flex; justify-content: center; align-items: center;gap:50px">

          <div style="height: 500px; border:3px solid #ccc; overflow-y: scroll; width: 800px; font-weight: bold;">
            <ng-container *ngFor="let topic of filteredTopics">
              <div class="searchedThema" style="padding: 20px; border-bottom: 1px solid #ccc;">

                <div class="green-hover" (click)="toggleIndicators(topic)"
                  style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                  <mat-checkbox [(ngModel)]="topic.checked" [checked]="topic.checked" [value]="topic.Bereich"
                    (change)="onChange(topic, $event)" color="#10684E" disabled>
                    <h3 style="color: black;"> {{ topic.Bereich }} </h3>
                  </mat-checkbox>
                  <!-- <mat-icon aria-hidden="false" (click)="toggleIndicators(topic)" style="background-color: #10684E; color:white ; border-radius: 50%;" >arrow_drop_down</mat-icon> -->
                </div>


                <div *ngIf="topic.showIndicators">
                  <div style="display: flex;
                          justify-content: flex-start;
                          padding-bottom: 20px;
                          flex-wrap: wrap;
                          gap: 10px;
                          padding-top: 20px;
                          
                          flex-direction: column;
                          font-weight: 400;">
                    <span style="font-weight: 700;">Indikatoren:</span>
                    <ng-container *ngFor="let indi of topic.data">
                      <mat-checkbox *ngIf="indi.visible" class="searchedIndi" style="font-weight: 400;"
                        [(ngModel)]="indi.checked" [checked]="indi.checked" color="#10684E"
                        (change)="onCheckboxChange(topic, indi, $event.checked)">
                        {{ indi.Name }}
                      </mat-checkbox>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>



          <div
            style="display: flex; justify-content: center;align-items: center; flex-direction: column; position: relative;">

            <mat-form-field style="width: 250px;">
              <mat-label>Filter nach Thema</mat-label>
              <input matInput [(ngModel)]="topicFilter" (keyup)="filterTopics()" (focus)="showDropdown = true"
                (blur)="doNotShowDropdown()">
            </mat-form-field>

            <div class="autocomplete-dropdown" *ngIf="showDropdown && filteredTopics.length > 0">
              <div class="dropdown-item" *ngFor="let searchedTopic of filteredTopics"
                (click)="topicFilter= searchedTopic.Bereich;showDropdown = false;filterTopics()">
                {{ searchedTopic.Bereich }}
              </div>
            </div>



            <mat-form-field style="width: 250px;">
              <mat-label>Filter nach Indikator</mat-label>
              <input matInput [(ngModel)]="indiFilter" (keyup)="filterIndi()" (focus)="showDropdown2 = true"
                (blur)="doNotShowDropdown2()">
            </mat-form-field>

            <div class="autocomplete-dropdown2" *ngIf="showDropdown2 && filteredIndi.length > 0">
              <div *ngFor="let searchedTopic of filteredTopics">
                <div *ngFor="let searchedIndi of searchedTopic.data">
                  <div *ngIf="searchedIndi.visible" class="dropdown-item"
                    (click)="indiFilter= searchedIndi.Name ;showDropdown2 = false;filterIndi(); getTopicOfIndi(searchedTopic,searchedIndi)">
                    {{ searchedIndi.Name }}
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>





      </div>
      <br> <br> <br> <br>
      <div style="display: flex; justify-content: space-between;">

        <button mat-mini-fab aria-label="Example icon button with a home icon"
          (click)="showAnal = true; showDetail = false; showIndi = false"
          style="background-color: #10684E; color:white">
          <mat-icon>arrow_backward_ios </mat-icon>
        </button>


        <span style="margin-right: 0; " class="span2"> Indikatoren laden
          <button mat-mini-fab aria-label="Example icon button with a home icon" (click)="auswahl()"
            style="background-color: #10684E; color:white">
            <mat-icon>arrow_forward_ios </mat-icon>
          </button>
        </span>
      </div>

    </div>



    <div *ngIf="showIndi">
      <span class="span1"> Schritt 3: Auswahl Indikatoren </span>
      <br>
      <br>
      <span class="span2"> Wählen Sie im letzten Schritt die für Sie relevanten Indikatoren aus. </span>
      <br>
      <br>
      <br>
      <br>


      <ng-container *ngFor="let t of regioMetaData">
        <br *ngIf="t.checked">
        <span *ngIf="t.checked" class="span1"> {{ t.Bereich }} </span>
        <br *ngIf="t.checked">


        <ng-container *ngFor="let indi of t.data">
          <mat-checkbox *ngIf="t.checked" [checked]="indi.checked" color="#10684E"
            (change)="indi.color = t.color; indi.checked = $event.checked;"> {{ indi.Name }} </mat-checkbox>
        </ng-container>


      </ng-container>
      <br>


      <div style="display: flex; justify-content: space-between;">

        <button style="background-color: #10684E; color: white" mat-mini-fab
          aria-label="Example icon button with a home icon" (click)="showDetail = true; showIndi = false">
          <mat-icon>arrow_backward_ios </mat-icon>
        </button>

        <span style="margin-right: 0; " class="span2"> Auswahl festlegen
          <button mat-mini-fab aria-label="Example icon button with a home icon" (click)="auswahl()"
            style="background-color: #10684E; color: white">
            <mat-icon>arrow_forward_ios </mat-icon>
          </button>
        </span>
      </div>

    </div>





  </div>



</dialog>


<dialog id="dialog" *ngIf="datahubPath==2 || datahubPath ==3">
  <div style="width: 1200px; ">

    <button style="margin-left:96.8%; background-color: #10684E;color: white" mat-mini-fab (click)="close()">
      <mat-icon>close </mat-icon>
    </button>
    <br>
    <br>
    <div *ngIf="showAnal">
      <span class="span1"> Schritt 1: Auswahl Regionalebene </span>
      <br>
      <br>
      <span class="span2"> Wählen Sie im ersten Schritt die für Sie relevante Analysebene aus. </span>
      <br>
      <br>

      <mat-radio-group [(ngModel)]="selectedLevel" #radioGroup="matRadioGroup">
        <mat-radio-button value=3 (click)="land=3"> Landkreis</mat-radio-button>
        <mat-radio-button value=4 (click)="land=4"> Verbandsgemeinde</mat-radio-button>
        <mat-radio-button value=5 (click)="land=5"> Gemeinde</mat-radio-button>

      </mat-radio-group>

      <div style="display: flex; justify-content: end;">
        <span style="margin-right: 0; " class="span2"> Zur Karte
          <button mat-mini-fab (click)="themenauswahl(); showExport=true; selectMap()"
            style="background-color: #10684E; color: white;">
            <mat-icon>arrow_forward_ios </mat-icon>
          </button>
        </span>
      </div>
    </div>


    <div [class.hidden]="!(showExport && customData) || land==1 || land==4 || land==5">


      <span class="span1"> Daten Hochladen </span>
      <br>

      <!-- <div *ngIf="topicsLoaded">
          <mat-spinner></mat-spinner>
        </div> -->

      <div *ngIf="loading"
        style="width: 1190px; display: flex;justify-content: center;align-items: center; flex-direction: column;">
        <mat-spinner color="primary"></mat-spinner>
        <p>Loading...</p>
      </div>

      <div class="inOutData" [class.hidden]="loading">
        <div class="landkreisStyle">
          <div *ngFor="let land of selectLandkreis">
            <mat-checkbox [(ngModel)]="selectedLands[land]" (change)="toggleLayer(land)">
              {{ land}}
            </mat-checkbox>
          </div>
        </div>


        <div class="map-frame">
          <div id="mapSmall"></div>
        </div>



      </div>


      <div style="display: flex; justify-content: space-between;" *ngIf="!loading">

        <button style="background-color: #10684E; color: white" mat-mini-fab
          aria-label="Example icon button with a home icon"
          (click)="showDetail = false; showAnal = true; showExport=false;">
          <mat-icon>arrow_backward_ios </mat-icon>
        </button>

        <span style="margin-right: 0; " class="span2"> Daten importieren/erweitern
          <button mat-mini-fab aria-label="Example icon button with a home icon"
            (click)="customData=false;showUpload=true;showDetail=false;"
            style="background-color: #10684E; color: white">
            <mat-icon>arrow_forward_ios </mat-icon>
          </button>
        </span>

      </div>

    </div>



    <div [class.hidden]="!(showExport && customData) || land==1 || land==3 || land==5">
      <span class="span1"> Daten Hochladen 2 </span>
      <br>

      <div *ngIf="loading"
        style="width: 1190px; display: flex;justify-content: center;align-items: center; flex-direction: column;">
        <mat-spinner color="primary"></mat-spinner>
        <p>Loading...</p>
      </div>

      <div class="inOutData" [class.hidden]="loading">
        <div class="landkreisStyle">
          <div *ngFor="let land of verband">
            <mat-checkbox [(ngModel)]="selectedLandsVerband[land.Object_Nam]"
              (change)="toggleLayerVerband(land.Object_Nam)">
              {{ land.Object_Nam}}
            </mat-checkbox>
          </div>
        </div>


        <div class="map-frame">
          <div id="mapSmallVerband"></div>
        </div>



      </div>

      <div style="display: flex; justify-content: space-between;" *ngIf="!loading">

        <button style="background-color: #10684E; color: white" mat-mini-fab
          aria-label="Example icon button with a home icon"
          (click)="showDetail = false; showAnal = true; showExport=false;">
          <mat-icon>arrow_backward_ios </mat-icon>
        </button>

        <span style="margin-right: 0; " class="span2"> Daten importieren/erweitern
          <button mat-mini-fab aria-label="Example icon button with a home icon"
            (click)="customData=false;showUpload=true;showDetail=false;"
            style="background-color: #10684E; color: white">
            <mat-icon>arrow_forward_ios </mat-icon>
          </button>
        </span>

      </div>

    </div>


    <div [class.hidden]="!(showExport && customData) || land==1 || land==3 || land==4">
      <span class="span1"> Daten Hochladen 3 </span>
      <br>

      <div *ngIf="loading"
        style="width: 1190px; display: flex;justify-content: center;align-items: center; flex-direction: column;">
        <mat-spinner color="primary"></mat-spinner>
        <p>Loading...</p>
      </div>

      <div class="inOutData" [class.hidden]="loading" style="gap: 0; justify-content: space-between;">
        <div>
          <div
            style="display: flex; align-items: center; justify-content: space-between; border-bottom: 1px solid #ccc;">
            <button mat-raised-button color="primary" (click)="selectGemeinde()">Alle auswählen</button>
            <button mat-raised-button color="primary" (click)="unselectGemeinde()">Alle abwählen</button>
            <mat-form-field>
              <input matInput placeholder="suche nach Gemeinde" [(ngModel)]="filterGemeinde"
                (keyup)="searchedGemeinde()">
            </mat-form-field>

          </div>

          <div style="width: 700px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            height: 301px;
            flex-direction: column;
            overflow-x: scroll;
            gap: 8px;">
            <div *ngFor="let land of searchForGemeinde">
              <mat-checkbox [(ngModel)]="selectedLandsGmeinde[land.Object_Nam]"
                (change)="toggleLayerGmeinde(land.Object_Nam)">
                {{ land.Object_Nam}}
              </mat-checkbox>
            </div>
          </div>

        </div>


        <div class="map-frame">
          <div id="mapSmallGmeinde">

          </div>
        </div>



      </div>

      <div style="display: flex; justify-content: space-between;" *ngIf="!loading">

        <button style="background-color: #10684E; color: white" mat-mini-fab
          aria-label="Example icon button with a home icon"
          (click)="showDetail = false; showAnal = true; showExport=false;">
          <mat-icon>arrow_backward_ios </mat-icon>
        </button>

        <span style="margin-right: 0; " class="span2"> Daten importieren/erweitern
          <button mat-mini-fab aria-label="Example icon button with a home icon"
            (click)="customData=false;showUpload=true;showDetail=false;"
            style="background-color: #10684E; color: white">
            <mat-icon>arrow_forward_ios </mat-icon>
          </button>
        </span>

      </div>

    </div>



    <div *ngIf="showUpload"
      style="display: flex;justify-content: space-around;align-items: center;flex-direction: column;height: 300px">
      <h3 *ngIf="land!=5">Möchten Sie eigene Daten importieren oder unsere bereits vorhandenen Daten aus Datahub, die
        bis zum Jahr 2021 reichen, erweitern?</h3>
      <h3 *ngIf="land==5">Möchten Sie eigene Daten importieren ?</h3>
      <div style="display: flex; justify-content: space-around; width: 90%;">
        <button *ngIf="land!=5" mat-raised-button color="primary"
          (click)="dataIm=true; showUpload=false ;importValues=true;datahubPath=2;">Daten importieren</button>
        <button *ngIf="land==5" mat-raised-button color="primary"
          (click)="stepCounter=1.5; showUpload=false ;importValues=true;datahubPath=2">Daten importieren</button>
        <button *ngIf="land!=5" mat-raised-button color="primary"
          (click)="dataEr=true; showUpload=false; startTopic();importValues=false; ;datahubPath=3">Daten
          erweitern</button>
      </div>

      <div style="display: flex; justify-content: flex-start; width: 100%;">

        <button style="background-color: #10684E; color: white" mat-mini-fab
          aria-label="Example icon button with a home icon" (click)="showUpload = false;customData=true;">
          <mat-icon>arrow_backward_ios </mat-icon>
        </button>

      </div>
    </div>



    <div *ngIf="dataIm==true">

      <span class="span1"> Schritt 2: Auswahl Themen</span>
      <br>
      <br>
      <span class="span2"> Wählen Sie im diesem Schritt die für Sie relevanten Themen aus. </span>
      <br> <br> <br> <br>
      <div *ngIf="topicsLoaded">
        <mat-spinner></mat-spinner>
      </div>

      <div style="display: flex; justify-content: center; align-items: center;gap:50px">
        <div *ngIf="!topicsLoaded" class="arrangeBox">
          <mat-checkbox color="#10684E" *ngFor="let topic of regioMetaData" [checked]="topic.checked"
            [value]="topic.Bereich" (change)="onChange(topic, $event); resetInputs(topic)">
            {{ topic.Bereich }}
          </mat-checkbox>


          <span *ngIf="regioMetaData.length == 0" class="span2"> Keine Themen verfügbar </span>
        </div>

        <button *ngIf="land!=5" mat-raised-button color="primary" style="height: 80px;width: 250px;"
          (click)="stepCounter=1.5;dataIm=false;chooseThema=true; removeThemaChecked()">
          Kein passendes Thema gefunden? Hier können Sie Namen vergeben
        </button>
      </div>

      <!-- <div *ngIf="customPath">
          <mat-form-field style="width: 600px;">
            Alternativ können Sie Ihren eigenen Themennamen vergeben:
            <mat-label></mat-label>
            <input matInput>
          </mat-form-field>
        </div> -->



      <br> <br> <br> <br>
      <div style="display: flex; justify-content: space-between;">


        <button *ngIf="customPath" mat-mini-fab aria-label="Example icon button with a home icon"
          (click)="showUpload =true;dataIm=false" style="background-color: #10684E; color:white">
          <mat-icon>arrow_backward_ios </mat-icon>
        </button>


        <span style="margin-right: 0; " class="span2"> Zur Benennung des Indikators.
          <button mat-mini-fab aria-label="Example icon button with a home icon" (click)="stepCounter=1;dataIm=false"
            style="background-color: #10684E; color:white">
            <mat-icon>arrow_forward_ios </mat-icon>
          </button>
        </span>
      </div>

    </div>


    <div *ngIf="stepCounter==1">

      <div
        style="width: 100%; display: flex; justify-content: flex-start; align-items: center; flex-direction: column; gap: 50px;">

        <span class="span1"> Hier können Sie Ihrem Indikator einen Namen geben. </span>

        <div class="inputArrange" style="gap: 30px;">
          <mat-form-field class="example-full-width">
            <mat-label>Indikator</mat-label>
            <input matInput [(ngModel)]="inputIndi">
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>Indikator Beschreibung</mat-label>
            <input matInput [(ngModel)]="inputBesch">
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>Indikator Einheit</mat-label>
            <input matInput [(ngModel)]="inputUnit">
          </mat-form-field>
        </div>

      </div>


      <div style="display: flex; justify-content: space-between;">


        <button *ngIf="!chooseThema && land!=5 &&!ThemaViaEr" mat-mini-fab
          aria-label="Example icon button with a home icon" (click)="dataIm=true;stepCounter=0"
          style="background-color: #10684E; color:white">
          <mat-icon>arrow_backward_ios </mat-icon>
        </button>

        <button *ngIf="chooseThema  && land!=5 &&!ThemaViaEr" mat-mini-fab
          aria-label="Example icon button with a home icon" (click)="stepCounter=1.5"
          style="background-color: #10684E; color:white">
          <mat-icon>arrow_backward_ios </mat-icon>
        </button>

        <button *ngIf="ThemaViaEr && land!=5" mat-mini-fab aria-label="Example icon button with a home icon"
          (click)="stepCounter=1.5" style="background-color: #10684E; color:white">
          <mat-icon>arrow_backward_ios </mat-icon>
        </button>

        <button *ngIf="land==5 &&!ThemaViaEr" mat-mini-fab aria-label="Example icon button with a home icon"
          (click)="stepCounter=1.5" style="background-color: #10684E; color:white">
          <mat-icon>arrow_backward_ios </mat-icon>
        </button>




        <span style="margin-right: 0; " class="span2"> Daten Hochladen
          <button mat-mini-fab aria-label="Example icon button with a home icon"
            (click)="stepCounter=2; onSliderChange()" style="background-color: #10684E; color:white">
            <mat-icon>arrow_forward_ios </mat-icon>
          </button>
        </span>
      </div>

    </div>

    <div *ngIf="stepCounter==1.5">


      <div
        style="width: 100%; display: flex; justify-content: flex-start; align-items: center; flex-direction: column; gap: 50px;">

        <span class="span1"> Hier können Sie Ihrem Thema einen Namen geben. </span>


        <mat-form-field style="width: 300px;">
          <mat-label>Thema</mat-label>
          <input matInput [(ngModel)]="inputThema">
        </mat-form-field>
      </div>



      <div style="display: flex; justify-content: space-between;">


        <button *ngIf="land!=5 && !ThemaViaEr" mat-mini-fab aria-label="Example icon button with a home icon"
          (click)="dataIm=true;stepCounter=0;chooseThema=false" style="background-color: #10684E; color:white">
          <mat-icon>arrow_backward_ios </mat-icon>
        </button>

        <button *ngIf="land==5 && !ThemaViaEr" mat-mini-fab aria-label="Example icon button with a home icon"
          (click)="showUpload=true;stepCounter=0;chooseThema=false" style="background-color: #10684E; color:white">
          <mat-icon>arrow_backward_ios </mat-icon>
        </button>

        <button *ngIf="ThemaViaEr" mat-mini-fab aria-label="Example icon button with a home icon"
          (click)="stepCounter=3" style="background-color: #10684E; color:white">
          <mat-icon>arrow_backward_ios </mat-icon>
        </button>




        <span style="margin-right: 0; " class="span2"> Zur Benennung des Indikators.
          <button mat-mini-fab aria-label="Example icon button with a home icon" (click)="stepCounter=1;"
            style="background-color: #10684E; color:white">
            <mat-icon>arrow_forward_ios </mat-icon>
          </button>
        </span>
      </div>

    </div>







    <div *ngIf="stepCounter==2">

      <span class="span1"> Daten Hochladen </span>

      <div class="inOutData2">
        <mat-slider min="2000" max="2024" step="1" showTickMarks discrete [displayWith]="formatLabel"
          style="width: 500px;" (input)="onSliderChange()">
          <input matSliderStartThumb [(ngModel)]="minYearData">
          <input matSliderEndThumb [(ngModel)]="maxYearData">
        </mat-slider>
        <div>
          Ausgewählte Jahre: {{minYearData }} - {{ maxYearData }}
        </div>
      </div>

      <mat-tab-group #secondMatGroup>

        <mat-tab label="Copy/Paste">
          <div class="inOutData2">
            <div style="max-height: 400px;
                min-height: 200px;
                overflow-y: scroll;
                margin: 8px;
                width: 600px;
                border: 2px solid #ccc;">
              <table mat-table [dataSource]="dataEx.slice(1)" class="mat-elevation-z8" (paste)="data($event)">
                <!-- Predefined columns -->
                <ng-container matColumnDef="Gebietsname">
                  <th mat-header-cell *matHeaderCellDef>Gebietsname</th>
                  <td mat-cell *matCellDef="let element">{{ element[0] }}</td>
                </ng-container>
                <ng-container matColumnDef="years">
                  <th mat-header-cell *matHeaderCellDef>Years</th>
                  <td mat-cell *matCellDef="let element">{{ element[1] }}</td>
                </ng-container>
                <ng-container matColumnDef="data">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">Data</th>
                  <td mat-cell *matCellDef="let element" style="text-align: center;">
                    <mat-form-field class="example-full-width" style="width: 250px;">
                      <input matInput [(ngModel)]="element[2]" type="number">
                    </mat-form-field>
                  </td>
                </ng-container>
                <!-- Header and row definitions -->
                <tr mat-header-row *matHeaderRowDef="['Gebietsname', 'years', 'data']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['Gebietsname', 'years', 'data'];"></tr>
              </table>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Template runterladen ">
          <div class="inOutData2" style="height: 300px;">


            <button mat-raised-button color="primary" (click)="exportData();datahubPath=2"> bitte laden Sie unser
              Template runter</button>

            <input type="file" (change)="onFileChange($event)" accept=".xlsx" #fileUpload class="file-input">

            <div class="file-upload" *ngIf="downloaded">

              {{fileName || "noch keine Datei Hochgeladen"}}

              <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
                <mat-icon>attach_file</mat-icon>
              </button>
            </div>
          </div>
        </mat-tab>

      </mat-tab-group>

      <div style="display: flex; justify-content: space-between;">


        <button style="background-color: #10684E; color: white" mat-mini-fab
          aria-label="Example icon button with a home icon" (click)="stepCounter=1">
          <mat-icon>arrow_backward_ios </mat-icon>
        </button>


        <span style="margin-right: 0; " class="span2"> Auswahl festlegen
          <button mat-mini-fab aria-label="Example icon button with a home icon" (click)="auswahl()"
            style="background-color: #10684E; color: white">
            <mat-icon>arrow_forward_ios </mat-icon>
          </button>
        </span>

      </div>
    </div>






    <!--  erweitert Path   -->








    <div *ngIf="dataEr">

      <span class="span1"> Schritt 2: Auswahl Indikatoren </span>
      <br>
      <br>
      <span class="span2"> Wählen Sie im zweiten Schritt die für Sie relevanten Themen aus. </span>
      <br> <br> <br> <br>
      <div *ngIf="topicsLoaded">
        <mat-spinner></mat-spinner>
      </div>

      <div style="display: flex; justify-content: center; align-items: center;gap:50px">
        <div *ngIf="!topicsLoaded" class="arrangeBox">

          <span *ngIf="regioMetaData.length == 0" class="span2"> Keine Themen verfügbar </span>
        </div>

        <div style="height: 500px; border:3px solid #ccc; overflow-y: scroll; width: 800px; font-weight: bold;">
          <ng-container *ngFor="let topic of filteredTopics">
            <div class="searchedThema" style="padding: 20px; border-bottom: 1px solid #ccc;">

              <div class="green-hover" (click)="toggleIndicators(topic)"
                style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                <mat-checkbox [(ngModel)]="topic.checked" disabled>
                  <h3 style="color: black;"> {{ topic.Bereich }} </h3>
                </mat-checkbox>
                <!-- <mat-icon aria-hidden="false" (click)="toggleIndicators(topic)" style="background-color: #10684E; color:white ; border-radius: 50%;" >arrow_drop_down</mat-icon> -->
              </div>


              <div *ngIf="topic.showIndicators">
                <div style="display: flex;
                      justify-content: flex-start;
                      padding-bottom: 20px;
                      flex-wrap: wrap;
                      gap: 10px;
                      padding-top: 20px;
                      
                      flex-direction: column;
                      font-weight: 400;">
                  <span style="font-weight: 700;">Indikatoren:</span>
                  <ng-container *ngFor="let indi of topic.data">
                    <mat-checkbox *ngIf="indi.visible" class="searchedIndi" style="font-weight: 400;"
                      [(ngModel)]="indi.checked" (change)="checkIndicators(topic);checkOnlyOne(topic, indi)">
                      {{ indi.Name }}
                    </mat-checkbox>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>





        <div style="display: flex; justify-content: center;
            align-items: center; flex-direction: column; position: relative;">

          <mat-form-field style="width: 250px;">
            <mat-label>Filter nach Thema</mat-label>
            <input matInput [(ngModel)]="topicFilter" (keyup)="filterTopics()" (focus)="showDropdown = true"
              (blur)="doNotShowDropdown()">
          </mat-form-field>

          <div class="autocomplete-dropdown" *ngIf="showDropdown && filteredTopics.length > 0">
            <div class="dropdown-item" *ngFor="let searchedTopic of filteredTopics"
              (click)="topicFilter= searchedTopic.Bereich;showDropdown = false;filterTopics()">
              {{ searchedTopic.Bereich }}
            </div>
          </div>



          <mat-form-field style="width: 250px;">
            <mat-label>Filter nach Indikator</mat-label>
            <input matInput [(ngModel)]="indiFilter" (keyup)="filterIndi()" (focus)="showDropdown2 = true"
              (blur)="doNotShowDropdown2()">
          </mat-form-field>

          <div class="autocomplete-dropdown2" *ngIf="showDropdown2 && filteredIndi.length > 0">
            <div *ngFor="let searchedTopic of filteredTopics">
              <div *ngFor="let searchedIndi of searchedTopic.data">
                <div *ngIf="searchedIndi.visible" class="dropdown-item"
                  (click)="indiFilter= searchedIndi.Name ;showDropdown2 = false;filterIndi()">
                  {{ searchedIndi.Name }}
                </div>
              </div>
            </div>
          </div>


          <button mat-raised-button color="primary" style="height: 100px;width: 250px;"
            (click)="stepCounter=3;dataIm=false; dataEr=false">
            Kein passendes Thema/Indikator gefunden? Hier können Sie Namen vergeben und Werte importieren
          </button>
        </div>
      </div>


      <br> <br> <br> <br>
      <div style="display: flex; justify-content: space-between;">


        <button *ngIf="customPath" mat-mini-fab aria-label="Example icon button with a home icon"
          (click)="showUpload =true;dataEr=false" style="background-color: #10684E; color:white">
          <mat-icon>arrow_backward_ios </mat-icon>
        </button>


        <span style="margin-right: 0; " class="span2"> Daten Hochladen
          <button mat-mini-fab aria-label="Example icon button with a home icon"
            (click)="stepCounter=5;dataEr=false;onSliderChange();getIndiYearsAndDataAndUnit()"
            style="background-color: #10684E; color:white">
            <mat-icon>arrow_forward_ios </mat-icon>
          </button>
        </span>
      </div>

    </div>


    <div *ngIf="stepCounter==3">

      <div
        style="width: 100%; display: flex; justify-content: flex-start; align-items: center; flex-direction: column; gap: 50px;">


        <span class="span1"> Wollen Sie ein Thema/Indikator neu anlegen ? </span>


        <div style="display: flex; justify-content: space-around; width: 100%;">

          <button (click)="stepCounter=1.5;ThemaViaEr=true;importValues=true;datahubPath=2" mat-raised-button
            color="primary"> Ja </button>
          <button (click)="dataEr=true;stepCounter=0;importValues=false;datahubPath=3" mat-raised-button
            color="primary"> Nein </button>

        </div>

      </div>

    </div>


    <div *ngIf="stepCounter==5">

      <div *ngIf="!loadingEr"
        style="width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column;">
        <mat-spinner></mat-spinner>
        <p>Loading...</p>
      </div>

      <div *ngIf="loadingEr"
        style="width: 100%; display: flex; justify-content: flex-start; align-items: center; flex-direction: column; gap: 50px;">


        <div style="display: flex; justify-content: center; align-items: center;">
          <span class="span1"> {{indiForErTable.Name}}</span>
        </div>

        <div style="display: flex; justify-content: space-evenly;align-items: center; width: 800px;">
          <button mat-raised-button color="primary">
            Zeitraum: {{dataIndiTableValues.MinYear}} - {{dataIndiTableValues.MaxYear}}
          </button>

          <button mat-raised-button color="primary">
            Wertebereich : {{dataIndiTableValues.MinData}} - {{dataIndiTableValues.MaxData}}
          </button>

          <button mat-raised-button color="primary">
            Mittelwert: {{dataIndiTableValues.AverageData}}
          </button>
        </div>

        <div style=" max-height: 400px;
          min-height: 200px;
          overflow-y: scroll;
          width: 800px;
          border: 2px solid #ccc;">
          <table mat-table [dataSource]="dataIndiTable" class="mat-elevation-z8">
            <ng-container matColumnDef="gebietsname">
              <th mat-header-cell *matHeaderCellDef>Gebietsname</th>
              <td mat-cell *matCellDef="let element">{{element.gebietsname}}</td>
            </ng-container>

            <ng-container matColumnDef="year">
              <th mat-header-cell *matHeaderCellDef>Year</th>
              <td mat-cell *matCellDef="let element">{{element.year}}</td>
            </ng-container>

            <ng-container matColumnDef="data">
              <th mat-header-cell *matHeaderCellDef>Data</th>
              <td mat-cell *matCellDef="let element">{{element.data}}</td>
            </ng-container>

            <ng-container matColumnDef="einheit">
              <th mat-header-cell *matHeaderCellDef>Einheit</th>
              <td mat-cell *matCellDef="let element">{{element.einheit}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['gebietsname', 'year', 'data' , 'einheit' ]"></tr>
            <tr mat-row *matRowDef="let row; columns: ['gebietsname', 'year', 'data' , 'einheit'];"></tr>
          </table>
        </div>







        <div style="display: flex; justify-content: space-between; width: 100%;">


          <button *ngIf="customPath" mat-mini-fab aria-label="Example icon button with a home icon"
            (click)="stepCounter=0; dataEr=true" style="background-color: #10684E; color:white">
            <mat-icon>arrow_backward_ios </mat-icon>
          </button>


          <span style="margin-right: 0; " class="span2"> Zeitrahmen erweitern
            <button mat-mini-fab aria-label="Example icon button with a home icon" (click)="stepCounter=4"
              style="background-color: #10684E; color:white">
              <mat-icon>arrow_forward_ios </mat-icon>
            </button>
          </span>
        </div>


      </div>

    </div>



    <div *ngIf="stepCounter==4">

      <span class="span1"> Daten Hochladen </span>

      <div class="inOutData2">
        <mat-slider [min]="nextYear" max="2024" step="1" showTickMarks discrete [displayWith]="formatLabel"
          style="width: 500px;" (input)="onSliderChange()">
          <input matSliderStartThumb [(ngModel)]="minYearData2">
          <input matSliderEndThumb [(ngModel)]="maxYearData2">
        </mat-slider>
        <div>
          Ausgewählte Jahre: {{minYearData2 }} - {{ maxYearData2 }}
        </div>
      </div>

      <mat-tab-group #thirdMatGroup>

        <mat-tab label="Copy/Paste">
          <div class="inOutData2">
            <div style=" max-height: 400px;
                min-height: 200px;
                overflow-y: scroll;
                margin: 8px;
                width: 600px;
                border: 2px solid #ccc;">
              <table mat-table [dataSource]="dataEx.slice(1)" class="mat-elevation-z8" (paste)="data($event)">
                <!-- Predefined columns -->
                <ng-container matColumnDef="Gebietsname">
                  <th mat-header-cell *matHeaderCellDef>Gebietsname</th>
                  <td mat-cell *matCellDef="let element">{{ element[0] }}</td>
                </ng-container>
                <ng-container matColumnDef="years">
                  <th mat-header-cell *matHeaderCellDef>Years</th>
                  <td mat-cell *matCellDef="let element">{{ element[1] }}</td>
                </ng-container>
                <ng-container matColumnDef="data">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">Data</th>
                  <td mat-cell *matCellDef="let element" style="text-align: center;">
                    <mat-form-field class="example-full-width" style="width: 250px;">
                      <input matInput [(ngModel)]="element[2]" type="number">
                    </mat-form-field>
                  </td>
                </ng-container>
                <!-- Header and row definitions -->
                <tr mat-header-row *matHeaderRowDef="['Gebietsname', 'years', 'data']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['Gebietsname', 'years', 'data'];"></tr>
              </table>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Template runterladen ">
          <div class="inOutData2" style="height: 300px;">


            <button mat-raised-button color="primary" (click)="exportData();datahubPath=3"> bitte laden Sie unser
              Template runter</button>

            <input type="file" (change)="onFileChange($event)" accept=".xlsx" #fileUpload class="file-input">

            <div class="file-upload" *ngIf="downloaded">

              {{fileName || "noch keine Datei Hochgeladen"}}

              <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
                <mat-icon>attach_file</mat-icon>
              </button>
            </div>

          </div>
        </mat-tab>

      </mat-tab-group>




      <div style="display: flex; justify-content: space-between;">

        <button style="background-color: #10684E; color: white" mat-mini-fab
          aria-label="Example icon button with a home icon" (click)="stepCounter=5">
          <mat-icon>arrow_backward_ios </mat-icon>
        </button>

        <span style="margin-right: 0; " class="span2"> Auswahl festlegen
          <button mat-mini-fab aria-label="Example icon button with a home icon" (click)="auswahl()"
            style="background-color: #10684E; color: white">
            <mat-icon>arrow_forward_ios </mat-icon>
          </button>
        </span>

      </div>

    </div>
































    <div *ngIf="showIndi">
      <span class="span1"> Schritt 3: Auswahl Indikatoren </span>
      <br>
      <br>
      <span class="span2"> Wählen Sie im letzten Schritt die für Sie relevanten Indikatoren aus. </span>
      <br>
      <br>
      <br>
      <br>


      <ng-container *ngFor="let t of regioMetaData">
        <br *ngIf="t.checked">
        <span *ngIf="t.checked" class="span1"> {{ t.Bereich }} </span>
        <br *ngIf="t.checked">


        <ng-container *ngFor="let indi of t.data">
          <mat-checkbox *ngIf="t.checked" [checked]="indi.checked" color="#10684E"
            (change)="indi.color = t.color; indi.checked = $event.checked;"> {{ indi.Name }} </mat-checkbox>
        </ng-container>


      </ng-container>

      <!-- <div *ngIf="customPath">
          <mat-form-field style="width: 600px;">
            Alternativ können Sie Ihren eigenen Indikatornamen vergeben:
            <mat-label></mat-label>
            <input matInput>
          </mat-form-field>
        </div> -->
      <br>


      <div style="display: flex; justify-content: space-between;">

        <button style="background-color: #10684E; color: white" mat-mini-fab
          aria-label="Example icon button with a home icon" (click)="showDetail = true; showIndi = false">
          <mat-icon>arrow_backward_ios </mat-icon>
        </button>

        <span *ngIf="!customPath" style="margin-right: 0; " class="span2"> Auswahl festlegen
          <button mat-mini-fab aria-label="Example icon button with a home icon" (click)="auswahl()"
            style="background-color: #10684E; color: white">
            <mat-icon>arrow_forward_ios </mat-icon>
          </button>
        </span>

        <span *ngIf="customPath" style="margin-right: 0; " class="span2"> Auswahl festlegen
          <button mat-mini-fab aria-label="Example icon button with a home icon"
            (click)="showUpload=true; showIndi=false" style="background-color: #10684E; color: white">
            <mat-icon>arrow_forward_ios </mat-icon>
          </button>
        </span>

      </div>

    </div>

  </div>



</dialog>


<dialog id="info">
  <div style="width: 600px; ">
    <button style="margin-left:95%; background-color: #10684E; color: white" mat-mini-fab (click)="closeInfo()">
      <mat-icon>close </mat-icon>
    </button>
    <h3> Beschreibung des Indikators</h3>

    {{ info }}
  </div>
</dialog>