import { AfterContentInit, AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import L, { LayerGroup } from 'leaflet';
import { RegioService } from '../service/regioservice/regioservice.component';
import { PopupComponent } from './popup/popup.component';
import { EventEmitter } from '@angular/core';


@Component({
  selector: 're-map',
  templateUrl: './re-map.component.html',
  styleUrls: ['./re-map.component.css']
})
export class ReMapComponent  {
  map: any;
  layers!: Map<any, any>;
  markers!: Map<any, any>;

  locationIcon = L.icon({
    iconUrl: '../assets/icon/standort.png', 
    iconSize: [32, 32], 
    iconAnchor: [16, 32], 
    popupAnchor: [0, -32] ,

  });

  layerDefaultStyle = {
    fillColor: 'white',
    fillOpacity: 0.2,
    color: "black",
    weight: 0.5
  }

  layerEmptyStyle = {
    fill: false,
    color: "black",
    weight: 0.5
  }

  layerHighlightStyle = {
    fillColor: 'red',
    fillOpacity: 0.2,
    color: "black",
    weight: 0.5
  }


  @Input() center = [51.943889, 11.433056];
  @Input() zoom = 7;

  @Output() someEvent     = new EventEmitter();
  @Output() clickOnMap    = new EventEmitter();
  @Output() clickOnLayer  = new EventEmitter();
  @Output() clickOnMarker = new EventEmitter();
  @Output() dragEndMarker = new EventEmitter();
  @Output() clickOnBtn1   = new EventEmitter();
  @Output() clickOnBtn2   = new EventEmitter();
  @Output() clickOnBtn3   = new EventEmitter();
  @Output() clickOnBtn4   = new EventEmitter();
  @Output() clickOnBtn5   = new EventEmitter();


  constructor(private regioService: RegioService,
    private viewContainerRef: ViewContainerRef) {

  }




  async getAndAddLayerByKeyAndLevel(key: string = '15', level: number = 3) {

    if (!this.map) { console.warn("Error, map not initialized"); return; }

    let l = await this.regioService.getShapefileByIdAndLevel('15', level);
    //@ts-ignore
    l[0].forEach((layer: any) => {
      let lay = L.geoJSON(layer.geometry, { style: this.layerDefaultStyle })
      lay.bindPopup("<b> " + layer.Object_Nam + "</b>")
      lay.on('click', () => {
        this.clickOnLayer.emit({ id: layer.Object_Nam, plain: layer })
      })
      this.map.addLayer(lay)

      this.layers.set(layer.Object_Nam, { id: layer.Object_Nam, plain: layer, leaflet: lay })
      console.log(this.layers)
    });

  }




  /**
   * Updates an existing layer in the layer storage by removing the old layer and adding a new one with updated properties.
   * If no new properties (layer, popup, style) are provided, the method will use the current values from the stored layer.
   *
   * @param {any} id - The identifier of the layer to be updated. This is used to find the existing layer in the storage.
   * @param {any} [layer] - The new layer to be added. If not provided, the current layer in the storage will be used.
   * @param {any} [popup] - The new popup content for the layer. If not provided, the current popup from the stored layer will be used.
   * @param {any} [style] - The new style for the layer. If not provided, the current style from the stored layer will be used.
   *
   * @throws {Error} If the layer with the specified `id` does not exist in the storage.
   */
  public updateLayer(id: any, layer: any, popup: any, style: any) {

    if (!this.layers.has(id)) {
      console.error('Not in storage ', id)
      return
    }
    let l = this.layers.get(id);
    if (!layer) { layer = l.plain }
    if (!popup) { popup = l.popup }
    if (!style) { style = l.style }
    this.removeLayer(id);
    this.addLayer(id, layer, popup, style)

  }

  public removeLayer(id: any) {
    let l = this.layers.get(id);

    if (l) {
      this.map.removeLayer(l.leaflet)
      // Check if the layer is removed
      if (!this.map.hasLayer(l.leaflet)) {
        this.layers.delete(id)
      } else {
        console.error("Layer removal from map failed: ", id);
      }
    } else {
      console.error("Layer not in storage: ", id);
    }


  }



  public addLayer(id: any, layer: any, popup: any, style: any = this.layerDefaultStyle) {


    if (this.layers.has(id)) { console.log(id + ' already on map'); return }

    const popupContent = this.createDynamicPopupContent(popup, layer);

    let lay = L.geoJSON(layer.geometry, { style: style })
    lay.bindPopup(popupContent)
    lay.on('click', () => { this.clickOnLayer.emit({ id: id, plain: layer }) })

    lay.on('popupopen', () => {

      setTimeout(() => {
        const ctn = document.querySelector('.leaflet-popup-content');

        // Füge Event-Listener hinzu
        const btn1 = ctn?.querySelector('#btn1');
        const btn2 = ctn?.querySelector('#btn2');
        const btn3 = ctn?.querySelector('#btn3');
        const btn4 = ctn?.querySelector('#btn4');
        const btn5 = ctn?.querySelector('#btn5');

        const btn1Click = () => { this.clickOnBtn1.emit(id); };
        const btn2Click = () => { this.clickOnBtn2.emit(id); };
        const btn3Click = () => { this.clickOnBtn3.emit(id); };
        const btn4Click = () => { this.clickOnBtn4.emit(id); };
        const btn5Click = () => { this.clickOnBtn5.emit(id); };

        btn1?.addEventListener('click', btn1Click);
        btn2?.addEventListener('click', btn2Click);
        btn3?.addEventListener('click', btn3Click);
        btn4?.addEventListener('click', btn4Click);
        btn5?.addEventListener('click', btn5Click);

        // Speichere die Event-Listener, damit du sie später entfernen kannst
        lay.on('popupclose', () => {


          // Entferne die Event-Listener, wenn das Popup geschlossen wird
          btn1?.removeEventListener('click', btn1Click);
          btn2?.removeEventListener('click', btn2Click);
          btn3?.removeEventListener('click', btn3Click);
          btn4?.removeEventListener('click', btn4Click);
          btn5?.removeEventListener('click', btn5Click);
        });
      }, 500)
    });


    this.map.addLayer(lay)
    this.layers.set(id, { id: id, plain: layer, leaflet: lay, popup: popup, style: style })


  }


  public addMarker(id: any, lat: any, lng: any, content: any, icon: L.Icon, popup: any, draggable = false) {
    if (this.markers.has(id)) { console.log(id + ' already on map'); return }
   
    let marker = L.marker([lat, lng], { icon: icon , draggable : draggable})
    
    if(popup) {
      const popupContent = this.createDynamicPopupContent(popup, content); 
      marker.bindPopup(popupContent)
      }

    marker.on('click', () => { this.clickOnMarker.emit({ id: id }) })
    marker.on('dragend', (event) => { 
      this.dragEndMarker.emit({ id: id, latlng:  event.target.getLatLng() }) 
  })

    marker.on('popupopen', () => {
      setTimeout(() => {
        const ctn = document.querySelector('.leaflet-popup-content');

        // Füge Event-Listener hinzu
        const btn1 = ctn?.querySelector('#btn1');
        const btn2 = ctn?.querySelector('#btn2');
        const btn3 = ctn?.querySelector('#btn3');
        const btn4 = ctn?.querySelector('#btn4');
        const btn5 = ctn?.querySelector('#btn5');

        const btn1Click = () => { this.clickOnBtn1.emit(id); };
        const btn2Click = () => { this.clickOnBtn2.emit(id); };
        const btn3Click = () => { this.clickOnBtn3.emit(id); };
        const btn4Click = () => { this.clickOnBtn4.emit(id); };
        const btn5Click = () => { this.clickOnBtn5.emit(id); };

        btn1?.addEventListener('click', btn1Click);
        btn2?.addEventListener('click', btn2Click);
        btn3?.addEventListener('click', btn3Click);
        btn4?.addEventListener('click', btn4Click);
        btn5?.addEventListener('click', btn5Click);

        // Speichere die Event-Listener, damit du sie später entfernen kannst
        marker.on('popupclose', () => {


          // Entferne die Event-Listener, wenn das Popup geschlossen wird
          btn1?.removeEventListener('click', btn1Click);
          btn2?.removeEventListener('click', btn2Click);
          btn3?.removeEventListener('click', btn3Click);
          btn4?.removeEventListener('click', btn4Click);
          btn5?.removeEventListener('click', btn5Click);
        });
      }, 500)
    });
    marker.addTo(this.map)

    this.markers.set(id, { id: id, lat: lat, lng: lng, plain: content, leaflet: marker, icon: icon, popup: popup, draggable: draggable })
  }

  public removeMarker(id: any) {
    let m = this.markers.get(id);
    if (m) {
      this.map.removeLayer(m.leaflet)
      // Check if the layer is removed
      if (!this.map.hasLayer(m.leaflet)) {
        this.markers.delete(id)
      } else {
        console.error("marker removal from map failed: ", id);
      }
    } else {
      console.error("marker not in storage: ", id);
    }

  }

  public updateMarker(id: any, lat: any, lng: any, content: any, icon = this.locationIcon, popup: any, drag: boolean) {


    
    if (!this.markers.has(id)) {
      console.error('Not in storage ', id)
      return
    }
    let m = this.markers.get(id);
    if (!lat) { lat = m.lat }
    if (!lng) { lng = m.lng }
    if (!content) { content = m.plain }
    if (!icon) { icon = m.icon }
    if (!popup) { popup = m.popup }
    if (!drag) { drag = m.draggable }

    this.removeMarker(id);
    this.addMarker(id, lat, lng, content, icon, popup, drag)

  }

  public removeAllMarkers() {
    this.markers.forEach(m => {
      this.map.removeLayer(m.leaflet)
      this.markers.delete(m.id)
  });

  }

  public removeAllLayers() {
    this.layers.forEach(l => {
      this.map.removeLayer(l.leaflet)
      this.layers.delete(l.id) })
  }


  public focusMapOnId(id: string, openPopup = true) {

    let l = this.layers.get(id);
    if(l) {
      this.map.fitBounds(l.leaflet.getBounds());
      if(openPopup) l.leaflet.openPopup()
      return
    }

    let m = this.markers.get(id);
    if(m) {
      var bounds = L.latLngBounds(m.leaflet.getLatLng(), m.leaflet.getLatLng());
      this.map.fitBounds(bounds);
      if(openPopup) m.leaflet.openPopup()
    }


  }

  public focusMapOnLatLng() {

  }

  private createDynamicPopupContent(componentType: any, content: any): string {
    const popupContainer = document.createElement('div');
    let componentRef: ComponentRef<PopupComponent>; // Typ explizit setzen


    // Erstelle eine Instanz der übergebenen Komponente ohne ComponentFactoryResolver
    componentRef = this.viewContainerRef.createComponent(componentType);
    if (componentRef.instance) {
      console.log('ctn', content)
      componentRef.instance.json = content;

      componentRef.changeDetectorRef.detectChanges();

      popupContainer.appendChild(componentRef.location.nativeElement);
    }

    return popupContainer.outerHTML;
  }

  public getMap() {
    return this.map
  }

  public getLayers() {
    return this.layers
  }
  public getMarker() {
    return this.markers
  }

  public initMap(): void {

    if(this.map) return
    this.map = L.map('map', {
      //@ts-ignore
      center: this.center,
      zoom: this.zoom,
    });


    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);

    this.map.on('click', (e: any) => {
      let pixelCoordinates = this.map.latLngToContainerPoint(e.latlng)

      this.clickOnMap.emit(
        { latlng: e.latlng, coordinates: pixelCoordinates }



      )
    })

    this.layers = new Map();
    this.markers = new Map();

  }

}
